import api from '@/base/utils/request'
import download from "@/base/utils/download";

export const getList = (data) => {
  return api({
    url: '/admin/college/teacher/index',
    method: 'post',
    data,
  })
}
export const saveSort = (data) => {
  return api({
    url: '/admin/college/teacher/saveSort',
    method: 'post',
    data,
  })
}

export const getDetail = (data) => {
  return api({
    url: '/admin/college/teacher/detail',
    method: 'post',
    data,
  })
}

export const saveDetail = (data) => {
  return api({
    url: '/admin/college/teacher/save',
    method: 'post',
    data,
  })
}
// 删除移入回收站
export const softDelete = (data) => {
  return api({
    url: '/admin/college/teacher/softDelete',
    method: 'post',
    data,
  })
}

// 回收站永久删除
export const Delete = (data) => {
  return api({
    url: '/admin/college/teacher/delete',
    method: 'post',
    data,
  })
}

// 回收站还原数据
export const putBack = (data) => {
  return api({
    url: '/admin/college/teacher/putBack',
    method: 'post',
    data,
  })
}
// 下载导出
export const exportFile = data => {
  return download({
    url: "/admin/college/teacher/exportIndex",
    method: "post",
    data,
    download: true
  });
};